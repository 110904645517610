@tailwind base;
@tailwind components;
@tailwind utilities;
.text-stroke {
    -webkit-text-stroke: 0.3px black; /* For Safari */
    text-stroke: 0.3px black; /* For other browsers */
  }
  /* Width of the scrollbar */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #1F2937; /* Replace with bg-slate-900 */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  
  html {
    scroll-padding-top:300px;
}